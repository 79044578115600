import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/plugins/i18n"; // 引入

if (window.tysso) {
  window.tysso.init({ appId: process.env.VUE_APP_SSOID });
} else {
  window.qcsso.init({ appId: process.env.VUE_APP_SSOID });
}

createApp(App).use(i18n).use(store).use(router).mount("#app");

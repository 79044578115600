<template>
  <div class="popup-theme" id="invoice-detail">
    <div class="popup-container">
      <div class="overlay-bg" @click="$emit('closeModal')"></div>

      <div class="popup-content" @click.stop>
        <a class="popup-close-x" @click="$emit('closeModal')"
          ><i class="icon-close fa-solid fa-xmark"></i
        ></a>
        <h3>發票捐贈明細</h3>
        <div v-if="!record">網站錯誤，無法取得捐贈明細，請稍後再試</div>
        <div v-else>
          <div class="big-icon-point">{{ displayedRewardStatus }}</div>

          <h4 v-show="someDonationsFailed" class="red-text" align="center">部分發票捐贈失敗</h4>

          <div class="detail-list">
            <b>捐贈時間</b>
            <time>{{ displayTsToDateTime(record.createdAt) }}</time>
          </div>

          <div class="detail-list">
            <b>捐贈單位</b>
            <span>{{ record.socialWelfareName }}</span>
          </div>

          <div class="detail-list">
            <b>捐贈發票</b>
            <div class="invices">
              <div
                v-for="inv in record.invoices"
                :key="inv.invNum"
                :class="{
                  'red-text': inv.invStatus === 'failed' || inv.invStatus === 'cancelled',
                }"
              >
                <b>${{ inv.invAmount }}</b
                ><i>．</i>
                <span>{{ inv.invNum }}</span>
              </div>
            </div>
          </div>
          <div class="detail-list">
            <b>注意事項</b>
            <div class="invoices">
              捐贈失敗可能原因包括網路連線問題、該發票已被捐贈或具買方統編、同一賣家發票當日已捐贈超過3張。請確認發票狀態或稍後重新嘗試。
            </div>
          </div>

          <div class="btns">
            <a class="pink-btn full-btn popup-close" @click="$emit('closeModal')">確認</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onBeforeUnmount } from "vue";
import moment from "moment";

export default {
  name: "DonationDetail",
  props: ["record"],
  setup(props) {
    onMounted(() => {
      document.body.style.overflow = "hidden";
    });

    onBeforeUnmount(() => {
      document.body.style.overflow = "scroll";
    });

    const someDonationsFailed = computed(() => {
      for (const inv of props.record.invoices) {
        if (inv.invStatus === "failed" || inv.invStatus === "cancelled") {
          return true;
        }
      }
      return false;
    });

    const displayedRewardStatus = computed(() => {
      if (props.record.status === "processing" || props.record.rewardStatus === "fail")
        return "處理中";

      if (props.record.rewardStatus === "insufficient") return "獎勵已發完";

      return `${props.record.rewardDesc} x ${props.record.rewardAmount}`;
    });

    const displayTsToDateTime = (ts) => moment(ts * 1000).format("YYYY/MM/DD HH:mm");

    return { someDonationsFailed, displayTsToDateTime, displayedRewardStatus };
  },
};
</script>

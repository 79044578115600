const mutations = {
  selectDonee(state, value) {
    state.donee = value;
  },
  getUser(state, value) {
    state.user = value;
  },
  getCampaignInfo(state, value) {
    state.campaignInfo = value;
  },
  getSocialWelfareList(state, value) {
    state.socialWelfareList = value;
  },
};

export default mutations;

<template>
  <label v-if="hasRewardDistributed" style="color:red">！獎勵已發送完畢！</label>
  <h1>{{ info.campaignName }}</h1>
  <div>{{ compaignHost }}</div>
  <time>{{ displayTsToDate(info.startAt) }} - {{ displayTsToDate(info.endAt) }}</time>
  <div class="info-list">
    <div>
      <b>活動獎勵</b>
      <span>{{ info.rewardDesc }} / 發票</span>
    </div>
    <div>
      <b>發票金額限制</b>
      <span>NT$10 以上</span>
    </div>
    <div>
      <b>發票累積捐贈數量</b>
      <span>{{ info.totalInvoices }}</span>
    </div>
  </div>
  <p>{{ info.campaignDesc }}</p>
  <div>
    <p>注意事項：</p>
    <ol>
      <li>1. 此資料以財政部提供的資料為主。</li>
      <li>
        2.
        捐贈發票後，系統將與財政部資料進行比對，確認捐贈成功才會發放點數，請於捐贈1~2天後確認點數是否入帳。
      </li>
      <li>3. 活動獎勵有限送完為止，若捐贈後獎勵發送完畢，恕無法取消發票捐贈、也無法補發點數。</li>
      <li>4. 同一賣方之發票每人每天只限捐贈3張。</li>
      <li>
        5.
        捐贈失敗可能原因如下：網路連線問題、該發票已被捐贈或具買方統編、同一賣家發票當日已捐贈超過3張。如有失敗，請確認發票狀態或稍後重試。
      </li>
    </ol>
  </div>
</template>

<script>
import moment from "moment";
import { computed } from "vue";

export default {
  name: "Campaign",
  props: ["info"],
  setup(props) {
    const displayTsToDate = (ts) => moment(ts * 1000).format("YYYY/MM/DD");
    const compaignHost = process.env.VUE_APP_CAMPAIGN_HOST;
    // const upperInvoiceAmount = process.env.VUE_APP_UPPER_INOVICE_AMOUNT ? Number(process.env.VUE_APP_UPPER_INOVICE_AMOUNT): null;
    const hasRewardDistributed = computed(() => {
      const upperInvoiceAmount = process.env.VUE_APP_UPPER_INOVICE_AMOUNT
        ? Number(process.env.VUE_APP_UPPER_INOVICE_AMOUNT)
        : null;
      if (
        upperInvoiceAmount &&
        props?.info?.totalInvoices &&
        Number(props.info.totalInvoices) >= upperInvoiceAmount
      ) {
        return true;
      } else {
        return false;
      }
    });
    return { displayTsToDate, compaignHost, hasRewardDistributed };
  },
};
</script>

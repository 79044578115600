import { createI18n } from "vue-i18n/index";
// 載入語言
// import tw from '@/lang/tw.json'
// import en from '@/lang/en.json'

function getLocale() {
  // 讀取cookie存入的當前語言
  const cookieLanguage = localStorage.getItem("locale");
  // 如果有返回當前語言
  if (cookieLanguage) {
    return cookieLanguage;
  }
  // 如果没有，獲取系統語言
  let language = navigator.language.toLowerCase();
  // 系統語言編碼和APP語言編碼不同時，如何轉換？如en-us/en-eg需統一轉成en
  if (language.startsWith("en-")) {
    language = "en";
  }
  // 獲取messages 語言，遍歷
  const locales = Object.keys(messages);
  for (const locale of locales) {
    // 如果message 包里面有系统语言返回
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  // 預設语言
  return "zh-tw";
}

function loadLocaleMessages() {
  const locales = require.context("@/lang", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const messages = loadLocaleMessages();

const i18n = createI18n({
  legacy: false,
  locale: getLocale(), // localStorage.getItem("locale") ?? "tw",           // 設定語言
  fallbackLocale: "zh-tw", // 若選擇的語言缺少翻譯則退回的語言
  messages,
});
export default i18n;

<template>
  <div v-if="!$store.state.user"></div>
  <div v-else class="body-container">
    <router-view />
  </div>
</template>

<script>
import { watchEffect } from "vue";
import { useStore } from "vuex";
import { getUserData } from "@/apis/users";

export default {
  setup() {
    const store = useStore();

    watchEffect(async () => {
      try {
        const sso = window.tysso ? window.tysso : window.qcsso;
        const userProfile = await sso.getProfile();
        const user = userProfile.user;
        console.log("user:" + JSON.stringify(user));

        const _params = {
          userId: user.id,
          memberId: user._json?.memberId,
          merchantId: user.merchantId || process.env.VUE_APP_MERCHANT_ID,
        };

        getUserData(_params)
          .then((response) => {
            const userData = response.data;
            console.log("userData:" + JSON.stringify(userData));

            store.commit("getUser", {
              userId: user.id,
              // memberId: +user._json.oid.split("/")[1],
              memberId: user._json?.memberId,
              cardNo: userData?.cardNo ? userData?.cardNo.trim() : null,
              phoneNumber: user._json?.phoneNumber,
              merchantId: user.merchantId || process.env.VUE_APP_MERCHANT_ID,
            });
          })
          .catch(() => {
            store.commit("getUser", {
              userId: user.id,
              // memberId: +user._json.oid.split("/")[1],
              memberId: user._json?.memberId,
              cardNo: null,
              phoneNumber: user._json?.phoneNumber,
              merchantId: user.merchantId || process.env.VUE_APP_MERCHANT_ID,
            });
          });
      } catch (err) {
        console.error(err);
      }
    });
  },
};
</script>

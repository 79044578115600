<template>
  <teleport to="body">
    <div class="popup-theme" id="donate-object">
      <div class="popup-container">
        <div class="overlay-bg" @click="$emit('closeModal')"></div>

        <div class="popup-content" @click.stop>
          <a class="popup-close-x" @click="$emit('closeModal')"
            ><i class="icon-close fa-solid fa-xmark"></i
          ></a>
          <h3>捐贈單位</h3>

          <div class="search-bar">
            <input type="text" placeholder="搜尋" v-model="searchTerm" />
            <button type="button">
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
          <div v-if="isError" class="object-list" align="center">
            <p>網路錯誤，無法取得捐贈單位資料</p>
            <div>
              <label @click="loadSocialWelfareList">
                <span
                  >重新取得捐贈單位
                  <i class="fa-solid fa-arrows-rotate"></i>
                </span>
              </label>
            </div>
          </div>
          <div v-else class="object-list">
            <div v-for="org in matchingSocialWelfare" :key="org.id">
              <input
                type="radio"
                name="object"
                :id="'object-' + org.id"
                :value="org"
                :checked="$store.state.donee?.id === org.id"
              />
              <label :for="'object-' + org.id" @click="handleClick(org)">
                <b>{{ org.loveCode }}</b>
                <span>{{ org.socialWelfareName }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { computed, reactive, toRefs, watchEffect, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { getSocialWelfareList } from "@/apis/fetchData";

export default {
  name: "SocialWelfareList",
  emits: ["closeModal", "clickDonateBtn"],
  setup(_, { emit }) {
    onMounted(() => {
      document.body.style.overflow = "hidden";
    });

    onBeforeUnmount(() => {
      document.body.style.overflow = "scroll";
    });

    const store = useStore();
    const state = reactive({
      searchTerm: "",
      isError: false,
      isLoading: false,
    });

    const matchingSocialWelfare = computed(() => {
      return store.state.socialWelfareList.filter(({ loveCode, socialWelfareName }) =>
        (loveCode + socialWelfareName).includes(state.searchTerm)
      );
    });

    const loadSocialWelfareList = async () => {
      const { user } = store.state;
      if (!user?.merchantId) return;
      try {
        const { data: socialWelfareData } = await getSocialWelfareList(user);
        store.commit("getSocialWelfareList", socialWelfareData);
        state.isError = false;
      } catch (err) {
        state.isError = true;
      }
    };

    watchEffect(() => {
      const { user, socialWelfareList } = store.state;
      if (!user?.merchantId || socialWelfareList.length) return;
      loadSocialWelfareList();
    });

    const handleClick = (donee) => {
      store.commit("selectDonee", {
        id: donee.id,
        name: donee.socialWelfareName,
      });
      emit("closeModal");
      emit("clickDonateBtn");
    };

    return {
      ...toRefs(state),
      matchingSocialWelfare,
      handleClick,
      loadSocialWelfareList,
    };
  },
};
</script>

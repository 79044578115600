<template>
  <teleport to="body">
    <div class="popup-theme">
      <div class="popup-container">
        <div class="overlay-bg" @click="handleClick"></div>
        <div class="popup-content" @click.stop>
          <a class="popup-close-x" @click="handleClick"
            ><i class="icon-close fa-solid fa-xmark"></i
          ></a>
          <div class="big-icon error"><i class="fa-solid fa-xmark"></i></div>
          <h3 align="center">{{ content.msg }}</h3>
          <h4 v-if="content.subMsg" align="center">{{ content.subMsg }}</h4>
          <p><br /></p>

          <div class="btns">
            <a class="pink-btn full-btn popup-close" @click="handleConfirmClick">確定</a>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { onMounted, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "ErrorPopup",
  props: ["content"],
  emits: ["closeModal"],
  setup(props, { emit }) {
    onMounted(() => {
      document.body.style.overflow = "hidden";
    });

    onBeforeUnmount(() => {
      document.body.style.overflow = "scroll";
    });

    const router = useRouter();
    const handleClick = () => {
      // if (props.content.redirectTo) {
      //   router.push({ name: props.content.redirectTo });
      // } else {
      //   emit("closeModal");
      // }
      emit("closeModal");
    };
    const handleConfirmClick = () => {
      if (props.content.redirectTo) {
        router.push({ name: props.content.redirectTo });
      } else {
        emit("closeModal");
      }
    };
    return { handleClick, handleConfirmClick };
  },
};
</script>

<template>
  <table class="invoice-list">
    <thead>
      <tr>
        <th>&nbsp;</th>
        <th>發票資訊</th>
        <th>發票金額</th>
      </tr>
    </thead>

    <tbody v-if="!userInvoices">
      <td colspan="3">財政部網路忙碌中，無法取得發票資料，請稍後再試</td>
    </tbody>
    <tbody v-else-if="invList.length">
      <tr v-for="inv in invList" :key="inv.invNum" :class="!inv.invDonatable ? 'check done' : ''">
        <td v-if="!inv.invDonatable">
          <i>{{ inv.donateMark ? "已捐" : "" }}</i>
        </td>
        <td v-else class="check">
          <input
            type="checkbox"
            :id="inv.invNum"
            :value="inv"
            v-model="selectedInvoices"
            :disabled="checkCheckboxDisabled(inv)"
          />
          <label :for="inv.invNum"></label>
        </td>
        <td>
          <div class="nowrap">
            {{ inv.formattedDate.replaceAll("/", "-") }}
            <b> {{ inv.invNum }}</b>
          </div>
          {{ inv.sellerName }}
        </td>
        <td class="money">{{ inv.amount }}</td>
      </tr>
    </tbody>
    <tbody v-else>
      <td colspan="3">無發票資料</td>
    </tbody>
  </table>
</template>

<script>
import moment from "moment";
import { computed, reactive, watchEffect, toRefs } from "vue";

export default {
  name: "InvoiceList",
  props: ["userInvoices"],
  emits: ["setDonationInvoices"],
  setup(props, { emit }) {
    const INVOICE_AMOUNT_LIMIT = Number(process.env.VUE_APP_INVOICE_AMOUNT_LIMIT) || 10;
    const state = reactive({
      selectedInvoices: [],
    });

    const invList = computed(() => {
      return props.userInvoices
        .filter(
          (inv) =>
            Number(inv.amount) >= INVOICE_AMOUNT_LIMIT && (inv.invDonatable || inv.donateMark)
        )
        .map((inv) => {
          const formattedDate = moment(inv.invDate.time).format("YYYY/MM/DD");
          return { ...inv, formattedDate };
        })
        .sort((invA, invB) => invA.invDate.time - invB.invDate.time);
    });

    const checkCheckboxDisabled = (inv) => {
      return (
        state.selectedInvoices.length >= INVOICE_AMOUNT_LIMIT &&
        !state.selectedInvoices.some((invoice) => invoice.invNum == inv.invNum)
      );
    };

    watchEffect(() => {
      emit("setDonationInvoices", state.selectedInvoices);
    });

    return { ...toRefs(state), invList, checkCheckboxDisabled };
  },
};
</script>

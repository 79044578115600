<template>
  <div class="loading">
    <div>
      <i class="icon-loading"></i>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
};
</script>

import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Records from "../views/Records.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/records",
    name: "Records",
    component: Records,
  },
  {
    path: "/carrier/edit",
    name: "CarrierEdit",
    components: {
      default: () => import(/* webpackChunkName: "CarrierEdit" */ "../views/CarrierEdit.vue"),
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  let sso = null;
  if (window.tysso) {
    sso = window.tysso;
  } else {
    sso = window.qcsso;
  }
  if (!sso.isLoggedIn()) {
    console.log(`redirectUrl: ${process.env.VUE_APP_HOST}${to.path}` );
    sso.login({
      redirectUrl: `${process.env.VUE_APP_HOST}${to.path}`,
    });
  } else {
    next();
  }
});

export default router;

import axios from "axios";
const apiHost = process.env.VUE_APP_API_HOST;

// const sso = window.tysso ? window.tysso : window.qcsso;

export function getUserData(user) {
  var config = {
    method: "get",
    url: `${apiHost}/apis/v1/merchants/${user.merchantId}/members/${user.userId}/profile`,
  };
  return axios(config);
}

export function updateUserCarrier(user) {
  var config = {
    method: "put",
    url: `${apiHost}/apis/v1/merchants/${user.merchantId}/members/${user.userId}/profile`,
    data: {
      cardNo: user.cardNo,
      cardEncrypt: user.cardEncrypt,
    },
  };
  return axios(config);
}

<template>
  <div class="form-list" @click="modalOpen = true">
    <b>捐贈單位</b>
    <span>
      <a id="donate-object-selected" :class="`popup ${$store.state.donee ? 'selected' : ''}`">
        <b>{{ $store.state.donee ? $store.state.donee.name : "選擇捐贈單位" }}</b>
        <i class="fa-solid fa-angle-right"></i>
      </a>
    </span>
    <SocialWelfareListPopup v-if="modalOpen" @closeModal="modalOpen = false" />
  </div>
  <div v-if="!$store.state.donee" class="form-msg">
    <div class="left">請選擇捐贈單位</div>
  </div>
</template>

<script>
import { ref } from "vue";
import SocialWelfareListPopup from "./SocialWelfareListPopup.vue";

export default {
  name: "Donee",
  components: { SocialWelfareListPopup },
  setup() {
    const modalOpen = ref(false);

    return { modalOpen };
  },
};
</script>
